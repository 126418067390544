<template>
    <div>
        <section id="page-title" class="page-title-parallax page-title-dark" style="background-image: url('/assets/images/about/parallax.jpg'); padding: 120px 0;" data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">

			<div class="container clearfix">
				<h1>Job Openings</h1>
				<span>Join our Fabulous Team of Intelligent Individuals</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Jobs</li>
				</ol>
			</div>

		</section><!-- #page-title end -->
        <!-- Content
		============================================= -->
		<section id="content">
			<div class="content-wrap">
				<div class="container clearfix">
                    
					<div class="row col-mb-50">
                        <!-- sidebar -->
                        <div class="col-md-4">
                            <Sidebar/>
                        </div>
                        <!-- JD  -->
						<div class="col-md-8">
							<div>
                                <h3 style="width: 100%;padding: 0 0 0.75rem;border-bottom: 2px solid #1ABC9C;">Solutions Engineer
                                <router-link to="/careers/solutions-engineer/form" class="button button-3d m-0 button-primary" style="float:right">Apply Now</router-link></h3>
                                <h4>Who we Are</h4>
                                <p>eFlexervices is a BPO company with a legacy spanning 22 years, we've honed our craft in providing exceptional quality and building unshakable trust. At eFlex, we're not just a BPO company - we're your partners in success. Our approach is all about finding the perfect match between talent and the organizations we support. We're not just investing in our work; we're investing in people, optimizing performance, and maximizing efficiency. We are all about exceptional quality and unwavering trust.</p>
                                <h4>What We Are Looking For</h4>
                                <p>Are you ready to take your expertise to the next level? At eFlexervices, we're not just hiring for a <strong>Solutions Engineer</strong> - we're empowering your talent to deliver quality and performance. Join us in shaping the success and making an impact that truly matters.</p>
                                <p>As a Solutions Engineer, you'll lead technical discussions and scope/manage/build integrations to ensure seamless adoption of our client's core platform. As a secondary responsibility, there are opportunities to deliver tailored demos and support the client for the Salesforce platform. Your expertise in technical scope, security, adoption, and usage will boost close rates, retention, and success, while your insights gather references, case studies, and feedback, establishing you as a subject matter expert.</p>

                                <div class="accordion accordion-bg">

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll be doing
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0"><h4>Integration Engineering:</h4>
                                            <li><i class="icon-ok"></i>Build custom API integrations using various IPaaS systems (e.g. Tray.io) and applications.</li>
                                            <li><i class="icon-ok"></i>Facilitate seamless integration of our client's relationship CRM with diverse technology stacks.</li>
                                        </ul>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0"><h4>Proof-of-Concept Design:</h4>
                                            <li><i class="icon-ok"></i>Collaborate with the Professional Services Team to design and support complex solutions.</li>
                                            <li><i class="icon-ok"></i>Oversee the implementation and success of pre-sales proofs-of-concept.</li>
                                            <li><i class="icon-ok"></i>Utilize project management skills and collaborate with technology partners as needed. </li>
                                        </ul>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0"><h4>Technical Pre-Sales Engineering:</h4>
                                            <li><i class="icon-ok"></i>Provide technical advice and support for sales opportunities, engaging with technical contacts, developers, and key stakeholders in prospect/customer organizations. </li>
                                            <li><i class="icon-ok"></i>Ensure accurate positioning of the client and address technical objections through effective communication. </li>
                                            <li><i class="icon-ok"></i>Conduct product demonstrations and present to prospect/customer contacts. </li>
                                            <li><i class="icon-ok"></i>Craft formal RFP response documents and high-level integration implementation plans. </li>
                                            <li><i class="icon-ok"></i>Collaborate closely with sales colleagues, contributing to qualification and sales strategies. </li>
                                        </ul>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0"><h4>Information Security & Data Privacy: </h4>
                                            <li><i class="icon-ok"></i>Complete RFI's/RFP's during the sales process, ensuring comprehensive responses. </li>
                                            <li><i class="icon-ok"></i>Serve as the security advocate for our customer base, addressing information security and data privacy concerns. </li>
                                            <li><i class="icon-ok"></i>Engage with C-level executives to discuss system architecture, security practices, policies, and data privacy measures.</li>
                                        </ul>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0"><h4>Others:</h4>
                                            <li><i class="icon-ok"></i>Resolve client-reported bugs and/or broken features in collaboration with Engineering and Product teams.</li>
                                            <li><i class="icon-ok"></i>Work with our Solutions Engineering team to understand, troubleshoot, and support client custom integrations. </li>
                                            <li><i class="icon-ok"></i>Advise a range of interested parties from developers to CTOs, adapting your approach depending on the audience and their technical aptitude. </li>
                                            <li><i class="icon-ok"></i>Collaborate with internal teams to improve process & documentation.</li>
                                            <li><i class="icon-ok"></i>Tackle other projects as assigned. Ideally, you love stretching out of your comfort zone to take on new challenges. </li>
                                        </ul>
                                    </div>

                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Qualifications and Requirements
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Bachelor's degree (BA/BS) in Computer Science, a related technical field, or equivalent practical experience.</li>
                                            <li><i class="icon-ok"></i>A minimum of 3 years of hands-on experience in technical support, professional services, engineering, or systems engineering. </li>
                                            <li><i class="icon-ok"></i>Demonstrated expertise in API utilization, with the ability to understand, implement, and build integrations using third-party APIs. </li>
                                            <li><i class="icon-ok"></i>Proficiency in JavaScript and/or Python, along with experience working with APIs and popular Integration Platform as a Service (IPaaS) systems, such as Tray.io.</li>
                                            <li><i class="icon-ok"></i>Problem-solving skills rooted in an engineering mindset, coupled with the ability to articulate solutions effectively to diverse audiences</li>
                                            <li><i class="icon-ok"></i>Excellent communication skills, coupled with well-defined time-management abilities to manage multiple projects simultaneously.</li>
                                            <li><i class="icon-ok"></i>Enthusiasm for taking on different responsibilities and contributing across various departments within the company.</li>
                                        </ul>
                                    </div>

                                     <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            Nice to have
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-ok"></i>Experience creating Statement of Works (SOWs)</li>
                                            <li><i class="icon-ok"></i>Experience with RFI & RFP process</li>
                                            <li><i class="icon-ok"></i>Experience with Single-Sign On IDP technologies</li>
                                            <li><i class="icon-ok"></i>A background in Security & data privacy</li>
                                            <li><i class="icon-ok"></i>SFDC Admin Certification and/or relevant experience and understanding of the Salesforce ecosystem, including collaboration with Salesforce system integrators.</li>
                                        </ul>
                                    </div>
                                    
                                    <div class="accordion-header">
                                        <div class="accordion-icon">
                                            <i class="accordion-closed icon-ok-circle"></i>
                                            <i class="accordion-open icon-remove-circle"></i>
                                        </div>
                                        <div class="accordion-title">
                                            What you'll enjoy at eFlex
                                        </div>
                                    </div>
                                    <div class="accordion-content">
                                        <p>At eFlex, we're not just a workplace - we're a community of playmakers, committed to fostering growth, learning, and personal connections. Here's what's in store for you:</p>
                                        <ul class="iconlist iconlist-color mb-0">
                                            <li><i class="icon-plus-sign"></i><strong>Living Our Values:</strong> We don't just talk the talk; we walk the walk. From learning and personal growth to caring deeply about our team and clients, our values are the compass guiding our vibrant workplace.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Competitive Compensation:</strong> Your hard work deserves recognition. Enjoy a competitive salary and benefits package, including comprehensive HMO coverage and optical reimbursements.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Flexible Work Options:</strong> Work your way! If you're outside Baguio City, embrace a permanent work-from-home setup. For those within Baguio, our hybrid work model offers the best of both worlds.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Wellness Matters:</strong> Recharge and refresh! Our flexible vacation and sick leaves empower you to prioritize your well-being, ensuring a healthy work-life balance.</li>
                                            <li><i class="icon-plus-sign"></i><strong>Pathways to Success:</strong> Your journey with us is brimming with growth opportunities. We're dedicated to nurturing your career and supporting your rise through the ranks.</li>
                                        </ul>
                                    </div>

                                    <p>Ready to embark on an enriching journey? Join the eFlex family and experience a workplace that values your individuality and success. Let's thrive together!</p>
                                    <router-link to="/careers/solutions-engineer/form" class="button button-3d m-0 button-primary" style="float:left">Apply Now</router-link>
                                </div>
                                <div class="divider divider-sm"></div>
                            </div>
						</div>
                        
                    </div>

				</div>
			</div>
		</section><!-- #content end -->

    </div>
</template>

<script>
import Sidebar from '@/components/CareerNav.vue'
export default {
    data() {
        return {
            
        }
    },
    components: {
        Sidebar
    },
    mounted() {
		window.scrollTo(0, 0)
	}
}
</script>